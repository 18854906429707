exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-experiments-index-js": () => import("./../../../src/pages/experiments/index.js" /* webpackChunkName: "component---src-pages-experiments-index-js" */),
  "component---src-pages-experiments-ready-player-gpt-jsx": () => import("./../../../src/pages/experiments/ready-player-gpt.jsx" /* webpackChunkName: "component---src-pages-experiments-ready-player-gpt-jsx" */),
  "component---src-pages-experiments-shader-fun-js": () => import("./../../../src/pages/experiments/shader-fun.js" /* webpackChunkName: "component---src-pages-experiments-shader-fun-js" */),
  "component---src-pages-experiments-shoe-configurator-js": () => import("./../../../src/pages/experiments/shoe-configurator.js" /* webpackChunkName: "component---src-pages-experiments-shoe-configurator-js" */),
  "component---src-pages-experiments-space-ship-js": () => import("./../../../src/pages/experiments/space-ship.js" /* webpackChunkName: "component---src-pages-experiments-space-ship-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-talks-index-js": () => import("./../../../src/pages/talks/index.js" /* webpackChunkName: "component---src-pages-talks-index-js" */),
  "component---src-pages-using-ssr-js": () => import("./../../../src/pages/using-ssr.js" /* webpackChunkName: "component---src-pages-using-ssr-js" */),
  "component---src-pages-using-typescript-tsx": () => import("./../../../src/pages/using-typescript.tsx" /* webpackChunkName: "component---src-pages-using-typescript-tsx" */),
  "component---src-templates-using-dsg-js": () => import("./../../../src/templates/using-dsg.js" /* webpackChunkName: "component---src-templates-using-dsg-js" */)
}

